import React from 'react';
import './ExpertiseEquipe.css';

function ExpertiseEquipe({ scrollToContato }) {
  return (
    <div className='ExpertiseEquipe-wrapper'>
        <div className='ExpertiseEquipe-container'>
          <div className='ExpertiseEquipe-content'>
          <h2>Suporte à Investigação Defensiva</h2>
          <p>
            Na Ethos Brasil, nossa equipe de especialistas combina tecnologia avançada e uma abordagem sistemática para coletar, avaliar e organizar informações. Garantimos um suporte à investigação defensiva robusto e eficaz.
          </p>
          <p>Nossa atuação envolve:</p>
          <ul>
            <li><i class="uil uil-check-circle"></i>Análise detalhada de documentos e registros;</li>
            <li><i class="uil uil-check-circle"></i>Entrevistas e coleta de depoimentos estratégicos;</li>
            <li><i class="uil uil-check-circle"></i>Verificação de antecedentes e histórico de envolvidos;</li>
            <li><i class="uil uil-check-circle"></i>Utilização de tecnologia para rastreamento e monitoramento;</li>
            <li><i class="uil uil-check-circle"></i>Contratação de peritos para laudos e exames periciais;</li>
            <li><i class="uil uil-check-circle"></i>Realização de reconstituições e pesquisas minuciosas em instituições públicas e privadas;</li>
            <li><i class="uil uil-check-circle"></i>Elaboração de relatórios detalhados e apresentação de informações.</li>
          </ul>
          <button onClick={scrollToContato}
            className='main-btn'>Entre em contato</button>
          </div>
          <div className='ExpertiseEquipe-img'>
          </div>
        </div>
    </div>
  );
}

export default ExpertiseEquipe;
