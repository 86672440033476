import React from 'react';
import './Sobre.css';

function Sobre({ scrollToContato }) {
  return (
    <div className='sobre-wrapper'>
        <div className='sobre-container'>
          <div className='sobre-content'>
            <h2>Ethos Brasil</h2>
          <p>A Ethos Brasil tem como objetivo auxiliar na conquista dos seus objetivos em processos judiciais e tratativas extrajudiciais.</p>
          <p>Para isso, oferecemos um conjunto de serviços abrangentes, que visam fortalecer a posição do cliente e aumentar as chances de sucesso.</p>
          <p>Atuamos na coleta, análise, organização e apresentação de evidências digitais e documentais, de maneira multidisciplinar.</p>
          <button onClick={scrollToContato}
            className='main-btn'>Entre em contato</button>
          </div>
          <div className='sobre-img'>
          </div>
        </div>
    </div>
  );
}

export default Sobre;
