import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import "./App.css";
import "./assets/styles/Reset.css";
import Hero from "./components/hero/Hero";
import FAQ from "./components/FAQ/FAQ";
import Sobre from "./components/Sobre/Sobre";
import CTA from "./components/CTA/CTA";
import CTAFinal from "./components/CTAFinal/CTAFinal";
import Especialidades from "./components/Especialidades/Especialidades";
import PrincipaisDesafios from "./components/PrincipaisDesafios/PrincipaisDesafios";
import ExpertiseEquipe from "./components/ExpertiseEquipe/ExpertiseEquipe";
import Formulario from "./components/formulario/Formulario";

function App() {
  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const rip = useRef();
  const contato = useRef();
  const newsletter = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      rip,
      contato,
      newsletter,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  const scrollToContato = () => {
    scrollToComponent("contato");
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />

        <div class="body-wrapper">
          <div class="body-container">
            <div ref={inicio}>
              <Hero scrollToContato={scrollToContato} />
            </div>
            <div ref={rip}>
              <ExpertiseEquipe scrollToContato={scrollToContato} />
            </div>
            <Especialidades scrollToContato={scrollToContato} />
            <CTA scrollToContato={scrollToContato} />
            <PrincipaisDesafios scrollToContato={scrollToContato} />
            <Sobre scrollToContato={scrollToContato} />
            <CTAFinal scrollToContato={scrollToContato} />

            <FAQ />
            <div ref={contato}>
              <Formulario scrollToContato={scrollToContato} />
            </div>
          </div>
        </div>

        <div ref={newsletter}>
          <Footer scrollToComponent={scrollToComponent} />
        </div>
      </div>
    </Router>
  );
}

export default App;
