import React from 'react';
import './CTA.css';

function CTA({ scrollToContato }) {
  return (
    <div className='CTA-container'>
        <h2>Assistência imediata</h2>
        <p>Clique no botão e entre em contato agora mesmo</p>
        
      <button onClick={scrollToContato}
        className='main-btn'>Entre em contato</button>
        
    </div>
  );
}

export default CTA;
