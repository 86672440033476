import React from 'react';
import './PrincipaisDesafios.css';

function PrincipaisDesafios({ scrollToContato }) {
  return (
    <div className='PrincipaisDesafios-wrapper'>
        <div className='PrincipaisDesafios-container'>
          <div className='PrincipaisDesafios-content'>
          <h2>Abordagem sistemática</h2>
          <p>
            Garantir uma investigação robusta e a organização sistemática de informações são desafios cruciais. Encontrar evidências relevantes, lidar com volumes extensos de dados e garantir a admissibilidade das informações exigem expertise, metodologia rigorosa e ferramentas adequadas.
          </p>
          <p>
            Na Ethos Brasil, oferecemos soluções personalizadas para superar esses desafios e alcançar o êxito em suas investigações.
          </p>
          <p>
            Nossa abordagem sistemática garante a coleta, análise e organização minuciosas das informações, utilizando metodologias comprovadas e tecnologia de última geração.
          </p>
          <button onClick={scrollToContato}
            className='main-btn'>Entre em contato</button>
          </div>
          <div className='PrincipaisDesafios-img'>
          </div>
        </div>
    </div>
  );
}

export default PrincipaisDesafios;
