import React, { useState } from "react";
import Agradecimento from "./Agradecimento.js";
import FormularioBasico from "./FormularioBasico.js";
import "./Formulario.css";

const Formulario = ({ scrollToContato }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    telefone: "",
    historico: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleBasicSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://jacquesgomes.com.br/gb/ethos-contato-store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: formData.nome,
          email: formData.email,
          telefone: formData.telefone,
          historico: formData.historico,
          origem: "https://consultoria.ethosbrasil.org/"
        }),
      });
      const data = await response.json();
      nextStep();
    } catch (error) {
      console.error('Erro ao enviar formulário básico:', error);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    scrollToContato();
  };

  return (
    <div className="formulario-wrapper">
      {step === 1 && (
        <FormularioBasico
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleBasicSubmit}
        />
      )}

      {step === 2 && <Agradecimento />}
    </div>
  );
};

export default Formulario;
