import React from 'react';
import './Especialidades.css';

const specialties = [
  { titulo: "Análise de Quebras de Sigilo", descricao: "Desvende violações de dados, proteja sua reputação e minimize danos. Identificamos a origem, o caminho dos dados e implementamos medidas preventivas." },
  { titulo: "Identificação de Nulidades", descricao: "Evite decisões anuladas e processos perdidos. Detectamos falhas processuais e legais em cada etapa, garantindo a solidez da sua defesa." },
  { titulo: "Revisão de Análise de Informações", descricao: "Obtenha uma avaliação crítica e detalhada de informações. Examinamos cada elemento, identificando pontos fortes e fracos, para construir uma estratégia robusta." },
  { titulo: "Inquirição de Depoentes", descricao: "Obtenha informações cruciais e depoimentos convincentes. Realizamos entrevistas estratégicas, com técnicas avançadas, para extrair o máximo de cada depoente." },
  { titulo: "Levantamento Profundo de Informações", descricao: "Descubra a verdade completa com nossa investigação meticulosa. Buscamos exaustivamente por dados, em diversas fontes. Desde registros públicos até inteligência de mercado." },
  { titulo: "Coleta, Análise e Validação de Informações Digitais", descricao: "Garanta a admissibilidade e o peso das evidências eletrônicas. Coletamos, analisamos e validamos informações digitais com rigor científico, tornando-as irrefutáveis em juízo." },
  { titulo: "Auditorias Diversas", descricao: "Aprimore seus processos e evite riscos desnecessários. Realizamos revisões sistemáticas em todas as áreas, identificando falhas e sugerindo medidas corretivas, para otimizar a sua operação." },
  { titulo: "Busca Patrimonial", descricao: "Localize bens e ativos, mesmo que estejam ocultos. Rastreamos com precisão o patrimônio de devedores, garantindo a satisfação do seu crédito." },
  { titulo: "Perícias Diversas", descricao: "Obtenha suporte técnico e científico impecável para as suas investigações. Realizamos exames rigorosos, com especialistas experientes. Fornecendo laudos precisos e imparciais, que fundamentam o seu caso." }
];

function Especialidades({ scrollToContato }) {
  return (
    <div className='Especialidades-wrapper'>
      <div className='Especialidades-container'>
        <h2>Especialidades</h2>
        <ul>
          {specialties.map((specialty, index) => (
            <li key={index}>
              <h3>{specialty.titulo}</h3>
              <span>{specialty.descricao}</span>
              <button onClick={scrollToContato}
                className='main-btn'><i class="uil uil-angle-double-right"></i>Entre em contato</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Especialidades;
