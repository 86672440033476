import React from 'react';

const Agradecimento = () => {
    return (
        <div className='agradecimento-container'>
            <h1>Obrigado por preencher o formulário!</h1>
            <h2>Deseja um contato imediato? </h2>
            <a href="https://wa.me/5584991009447" target="_blank" rel="noopener noreferrer" className='main-btn'>
                
                Clique aqui e fale conosco agora mesmo!
            </a>
        </div>
    );
};

export default Agradecimento;