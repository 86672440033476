import React from 'react';
import './CTAFinal.css';

function Depoimentos({ scrollToContato }) {
  return (
    <div className='depoimentos-container'>
      <div className="depoimentos-content">
        <h2>Fale com nossos especialistas agora mesmo!</h2>
        <button onClick={scrollToContato}
          className='main-btn alt'>Entre em contato</button>
      </div>
      <div className='overlay'></div>
    </div>
  );
}

export default Depoimentos;
