import React from "react";
import "./Hero.css";

function Hero({ scrollToContato }) {
  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="hero-wrapper">
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
          <h1>
            Dificuldade em{" "}
            <span>buscar, selecionar e analisar informações complexas?</span>{" "}
          </h1>
          <h1> Nós temos a solução!</h1>
          <button onClick={scrollToContato}
            className='main-btn'>Entre em contato</button>
        </div>
      </div>
    </div>
  );
}

export default Hero;
